<template>
  <div class="saleSample">
    <h1>销售取样单</h1>
    <el-form :inline="true">
      <el-form-item label="取样日期">
        <el-date-picker v-model="searchParam.sampleDates" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 220px;" />
      </el-form-item>
      <el-form-item label="客户">
        <el-select v-model="searchParam.custom" filterable style="width: 120px;" clearable>
          <el-option v-for='customItem in show.customList' :key="customItem.id" :label="customItem.value" :value="customItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="仓储">
        <el-select v-model="searchParam.storage" filterable style="width: 120px;" clearable>
          <el-option v-for='storageItem in show.storageList' :key="storageItem.id" :label="storageItem.value" :value="storageItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="info" @click="show.importShow = true">导入</el-button>
          <el-button type="primary" @click="add()">新建</el-button>
          <el-button type="warning" :disabled="!(show.selected.length == 1)" @click="update()">详情</el-button>
          <el-button type="danger" :disabled="!(show.selected.length >= 1)" @click="del()">删除</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" @selection-change="selectionChange()" ref="tabRef" border style="height: calc(100vh - 310px);">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column width="55" align="center">
        <template #default="scope">
          <el-link type="primary" @click="update(scope.row)">详情</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="sampleDate" label="取样日期" width="120" show-overflow-tooltip />
      <el-table-column prop="truckNumbers" label="车号" width="220" show-overflow-tooltip />
      <el-table-column prop="storage" label="仓储" width="120" show-overflow-tooltip />
      <el-table-column prop="custom" label="客户" width="120" show-overflow-tooltip />
      <el-table-column prop="customNote" label="销售品名" width="120" show-overflow-tooltip />
      <el-table-column prop="sampleQuantity" label="取样数量" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.sampleQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="sampleType" label="类型" width="120" show-overflow-tooltip />
      <el-table-column prop="quanshui" label="全水" width="120" show-overflow-tooltip />
      <el-table-column prop="neishui" label="内水" width="120" show-overflow-tooltip />
      <el-table-column prop="huifen" label="灰分" width="120" show-overflow-tooltip />
      <el-table-column prop="huifafen" label="挥发分" width="120" show-overflow-tooltip />
      <el-table-column prop="quanliu" label="全硫" width="120" show-overflow-tooltip />
      <el-table-column prop="gudingtan" label="固定碳" width="120" show-overflow-tooltip />
      <el-table-column prop="diweifareliangMj" label="热值·兆焦" width="120" show-overflow-tooltip />
      <el-table-column prop="diweifareliangKc" label="热值·大卡" width="120" show-overflow-tooltip />
      <el-table-column prop="saleAmount" label="结算金额" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.saleAmount) }}
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 10px;" />
    <el-pagination v-model:current-page="searchParam.pageNum" v-model:page-size="searchParam.pageSize" :page-sizes="[50, 100, 200]" background layout="total, sizes, prev, pager, next, jumper" :total="data.total" @change="commitFindList()" />
  </div>

  <el-dialog v-model="show.importShow" title="确认导入" width="500">
    <el-form>
      <el-form-item>
        <el-text>请先</el-text>
        <el-link type="primary" @click="importExcelTemplate()">下载导入模版</el-link>
        <el-text>，按照模板填写内容，再导入。</el-text>
        <br>
        <el-text type="danger">模板格式不对应可能造成倒入数据混乱，请注意。</el-text>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-upload :action="api.baseURL() + '/backend/saleSample/importExcel'" :headers="api.tokenHeader()" :on-progress="importExcelProgress" :on-success="importExcelSuccess" :on-error="importExcelError" :show-file-list="false">
          <el-button type="primary">导入</el-button>
        </el-upload>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import apiNative from '../../apis/apiNative'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'

const router = useRouter()
const tabRef = ref()

const data = reactive({
  total: 0,
  list: [],
})

const show = reactive({
  selected: [],
  customList: [],
  storageList: [],
  importShow: false,
})

api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'storage' } }).then(res => {
  show.storageList = res.dictList
})

const searchParam = reactive({})
let now = new Date()
const resetSearchParam = () => {
  searchParam.pageNum = 1
  searchParam.pageSize = 50
  searchParam.sampleDates = [now, now]
  searchParam.custom = null
  searchParam.storage = null
}
resetSearchParam()
const beforeSearchParam = cache.getObject(cache.keys.pageParam + 'saleSample')
if (beforeSearchParam) {
  Object.assign(searchParam, beforeSearchParam)
  cache.setObject(cache.keys.pageParam + 'saleSample', null)
}

const commitFindList = () => {
  const params = {}
  params.pageNum = searchParam.pageNum
  params.pageSize = searchParam.pageSize
  if (searchParam.sampleDates && searchParam.sampleDates.length == 2) {
    params.sampleDateGe = util.parseTime(searchParam.sampleDates[0], '{y}-{m}-{d}')
    params.sampleDateLe = util.parseTime(searchParam.sampleDates[1], '{y}-{m}-{d}')
  }
  params.custom = searchParam.custom
  params.storage = searchParam.storage
  api.get('/backend/saleSample/page', { params: params }).then(res => {
    data.list = res.list
    data.total = res.total
  })
}
commitFindList()

const selectionChange = () => {
  show.selected = tabRef.value.getSelectionRows()
}

const add = () => {
  cache.setObject(cache.keys.pageParam + 'saleSample', searchParam)
  cache.setObject(cache.keys.pageParam + 'saleSampleDetail', null)
  router.push('/saleSampleDetail')
}

const update = (row) => {
  cache.setObject(cache.keys.pageParam + 'saleSample', searchParam)
  const saleSample = row ? row : tabRef.value.getSelectionRows()[0]
  cache.setObject(cache.keys.pageParam + 'saleSampleDetail', saleSample)
  router.push('/saleSampleDetail')
}

const del = () => {
  const ids = tabRef.value.getSelectionRows().map(saleSample => saleSample.id)
  ElMessageBox.confirm('', {
    title: '确认删除',
    message: '将要删除选中的 ' + ids.length + ' 项。',
    autofocus: false,
    confirmButtonText: '确认',
    cancelButtonText: '取消'
  }).then(() => {
    api.post('/backend/saleSample/del', { ids: ids }).then(() => {
      ElMessage.success('删除成功')
      commitFindList()
    })
  })
}

const importExcelTemplate = () => {
  apiNative.get('/backend/saleSample/importExcelTemplate', {
    responseType: 'blob'
  }).then(res => {
    ElMessage.success('开始下载')
    util.downloadFile(res, '取样结果导入模版', 'xlsx')
  }).catch(() => {
    ElMessage.error('下载失败，请联系系统管理员')
  })
}

const importExcelProgress = () => {
  ElMessage.success('正在导入！如果数据量大可能时间较长，请耐心等待。')
}

const importExcelSuccess = (res) => {
  if (res.code != 'S000001') {
    ElMessage.error('导入失败，请联系系统管理员')
    return
  }
  show.importShow = false
  let message = '<p>本次导入，共计识别到：' + res.data.all + '条数据</p>'
  message += '<p>成功：<span style="color:green"> ' + res.data.success + ' </span>条数据</p>'
  message += '<p>失败：<span style="color:red"> ' + res.data.error + ' </span>条数据</p>'
  if (res.data.error > 0) {
    for (const failIndex in res.data.failRowList) {
      message += '<p>第 ' + res.data.failRowList[failIndex].row + ' 行：' + res.data.failRowList[failIndex].reason + '</p>'
    }
  }
  ElMessageBox.alert('', {
    title: '导入完成',
    message: message,
    autofocus: false,
    dangerouslyUseHTMLString: true,
    confirmButtonText: '确定'
  })
  res.data
}

const importExcelError = () => {
  ElMessage.error('导入失败，请联系系统管理员')
}
</script>

<style lang="less"></style>